export const StoreQueryTagEnum = {
  USER: "USER",
  RATING: "RATING",
  VEHICLE: "VEHICLE",
  VEHICLE_MANUFACTURER: "VEHICLE_MANUFACTURER",
  TRIP: "TRIP",
  DELIVERIES: "DELIVERIES",
  TRANSACTION: "TRANSACTION",
  PAYMENT: "PAYMENT",
  PAYMENT_BANK: "PAYMENT_BANK",
  PAYMENT_TRANSFER: "PAYMENT_TRANSFER",
  PAYMENT_BANK_ACCOUNT: "PAYMENT_BANK_ACCOUNT",
  PAYOUT: "PAYOUT",
  WALLET: "WALLET",
  PROMOTION: "PROMOTION",
  NOTIFICATION: "NOTIFICATION",
  TRACKER: "TRACKER",
  BONUS: "BONUS",
  BILL: "BILL",
  BILL_CATEGORY: "BILL_CATEGORY",
  BILL_PROVIDER: "BILL_PROVIDER",
  GROCERY_VENDOR: "GROCERY_VENDOR",
  GROCERY_CATEGORY: "GROCERY_CATEGORY",
  GROCERY_ITEM: "GROCERY_ITEM",
  GROCERY_MENU: "GROCERY_MENU",
  GROCERY_CART: "GROCERY_CART",
  GROCERY_VENDOR_ADMINS: "GROCERY_VENDOR_ADMINS",
  GROCERY_REFUND: "GROCERY_REFUND",
  PARTNER: "PARTNER",
  PRICING: "PRICING",
};
